export const SNBZ_TIMELINE = [
  {
    year: "1996",
    header: "první prodané prkno z&nbsp;garáže Snowboard Zezula &#128578;",
  },
  {
    year: "2003",
    header: "kluci zakládají vlastní značku",
    linkLabel: "Gravity Snowboards",
    odkaz: "//snowboard-zezula.cz/gravity",
    title: "Odkaz na webovou stránku www.snowboard-zezula.cz/gravity",
  },
  {
    year: "2004",
    header: "první e‑shop SNBZ je na světě",
  },
  {
    year: "2008",
    header: "igloonet dává vývojářskou ruku k&nbsp;dílu",
  },
  {
    year: "2010",
    header: "expanze na Slovensko",
  },
  {
    year: "2011",
    header: "začínámé spravovat PPC a&nbsp;SEO",
  },
  {
    year: "2012",
    listItems: [
      "<b>přestříháváme pásku nového e‑shopu od igloonetu na vlastním jádře</b>",
      "<b>nová třípatrová prodejna na Palackého třídě stojí a&nbsp;sbírá první zákazníky</b>",
    ],
  },
  {
    year: "2016",
    header: "spouštíme B2B",
  },
  {
    year: "2017",
    listItems: [
      "<b>expanze do Polska</b>",
      "<b>přebíráme e‑mailing a&nbsp;nastavujeme automatiku</b>",
      "<b>ve správě máme na 10 reklamních kanálů</b>",
    ],
  },
  {
    year: "2018",
    header: "sortiment prodejny se šíří do druhého patra",
  },
  {
    year: "2019",
    listItems: [
      "<b>po Evropě propagujeme doménu .COM</b>",
      "<b>krušná léta s covidem začínají –&nbsp;nutně se přizpůsobujeme turbulencím pro fashion a&nbsp;zimní sporty ne příliš přiznivému období</b>",
    ],
  },
  {
    year: "2022",
    header: "saháme po TikTok Ads a&nbsp;nasazujeme Google Analytics&nbsp;4",
  },
]
