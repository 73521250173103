import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  ContainerCustom,
  H2,
  Medailon,
  PaddingWrapper,
  Timeline,
  TitleH3,
} from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/snowboard-zezula/logo.png"
import Martin from "../../../images/reference/snowboard-zezula/reaction-marketing.png"
import Klaudie from "../../../images/team/mini/klaudie.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import Prodejna from "../../../images/reference/snowboard-zezula/snbz-prodejna.jpg"
import Oslava from "../../../images/reference/snowboard-zezula/snbz-martin-prodejna.jpg"
import Prodejna2 from "../../../images/reference/snowboard-zezula/prodejna.jpg"
import ShopScreen from "../../../images/reference/snowboard-zezula/snbz-eshop-screen.jpg"
import OgImage from "../../../images/reference/snowboard-zezula/og-image.png"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"
import { SNBZ_TIMELINE } from "../../../model/snbz-timeline"

const PaddingCustom = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 7rem 0;
  }
`

const SpanWithPadding = styled.span`
  padding-bottom: 1rem;
`

const ThreeImages = styled.div`
  img {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    img {
      margin-bottom: 0;
    }

    .middleImgWrapper {
      margin: 8rem 0 0 3rem;
    }

    .bottomImgWrapper {
      margin: -3rem auto 0;
      left: 0;
      max-width: 70%;
      z-index: 5;
    }
  }
`

const Snowboard: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Přes 10 let úspěšné spolupráce se Snowboard Zezula | igloonet.cz
        </title>
        <meta
          name="description"
          content="„Na igloonetu se mi líbí spojení profesionality s neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z paty,“ Martin Zezula, ředitel."
        />
        <meta
          property="og:title"
          content="Přes 10 let úspěšné spolupráce se Snowboard Zezula | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <ReferenceHeader
        companyName="SNOWBOARD ZEZULA"
        heading="Přes 10 let spolupráce protkané růstem brandu, tržeb i&nbsp;zahraniční expanzí"
        date="září 2010 -&nbsp;současnost"
      />

      <ReferenceImageText
        right
        img={Prodejna}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo Snowboard Zezula"
        link="//snowboard-zezula.cz/"
      >
        <p>
          Ve spolupráci se Snowboard Zezula se zhmotňuje námi preferovaný
          koncept byznysového partnerství –&nbsp;pomohli jsme vybudovat
          prosperující e‑shop, hostovaný na vlastních serverech, u&nbsp;kterého
          současně řídíme propagaci na víc než desítce reklamních kanálů
          v&nbsp;Česku, Polsku, na Slovensku a&nbsp;sekundárně po celé Evropě.
        </p>
        <p>
          Omrkněte ve zkratce celý vývoj a&nbsp;obsah naší spolupráce, ať víte,
          co od nás pod jednou střechou můžete všechno získat&nbsp;↓
        </p>
      </ReferenceImageText>

      <PaddingCustom>
        <ReferenceImageText
          video="https://www.youtube.com/embed/Rw8_ATFIAaE"
          responsiveTextOrder
          alt=""
        >
          <H2>Snowboard Zezula</H2>
          <p>
            První prkna jste si mohli od Zezuly odnést už v&nbsp;roce 1996.
            Stačilo najít ten správný garážový obchod.
          </p>
          <p>
            Dnes už top gear a&nbsp;fashion na snow‑, skate‑, long‑, wake‑,
            paddle‑ a&nbsp;další boarding nebo kola seženete ve dvou patrech
            obří prodejny v{" "}
            <a
              href="//snowboard-zezula.cz/snowboard-shop-brno"
              target="_blank"
              rel="noreferrer"
            >
              Brně
            </a>
            , anebo na čtyřjazyčném{" "}
            <a href="//snowboard-zezula.cz/" target="_blank" rel="noreferrer">
              e‑shopu{" "}
            </a>{" "}
            od igloonetu, který ročně obslouží na 40+&nbsp;tisíc objednávek.
          </p>
        </ReferenceImageText>
      </PaddingCustom>

      <Medailon personName="Martin Zezula" position="ředitel" img={Martin}>
        <p>
          Na igloonetu se mi líbí spojení profesionality s&nbsp;neformálností.
          Abych řekl pravdu, tak jste nám vytrhli trn z&nbsp;paty.
        </p>
      </Medailon>

      <ContainerCustom>
        <ThreeImages>
          <div className="d-md-flex">
            <img src={Oslava} alt="" width="1200" height="800" />
            <div className="middleImgWrapper">
              <img src={Prodejna} alt="" width="1324" height="624" />
            </div>
          </div>
          <div className="bottomImgWrapper">
            <img src={Prodejna2} alt="" width="1920" height="553" />
          </div>
        </ThreeImages>
      </ContainerCustom>

      <PaddingWrapper>
        <Container fluid className="text-center">
          <H2>Flow spolupráce</H2>
          <Timeline data={SNBZ_TIMELINE} />
        </Container>
      </PaddingWrapper>

      <ReferenceImageText img={ShopScreen}>
        <H2>Rozsah marketingové spolupráce</H2>
        <p>
          Na projektu se dlouhodobě orientujeme podle několika hlavních priorit,
          ovlivňujících cíle a&nbsp;další obsah práce. Jde poměrně prozaicky
          o&nbsp;
          <strong>
            růst popularity a&nbsp;budování značky Snowboard Zezula v tuzemsku
            i&nbsp;na zahraničních trzích, a&nbsp;to při růstu tržeb
            a&nbsp;provozního zisku
          </strong>
          .
        </p>
        <p>
          Nezůstáváme proto pouze u&nbsp;správy jednotlivých reklamních kanálů,
          ale řešíme společně celkový strategický rozvoj značky.{" "}
          <strong>A&nbsp;priority se nám dlouhodobě daří plnit</strong>, byť
          uplynulé covidové období způsobilo nemalé turbulence.
        </p>
      </ReferenceImageText>

      <Container className="text-center py-4">
        <Row className="mb-5">
          <Col
            md={6}
            className="d-flex flex-column align-items-center text-center"
          >
            <TitleH3 className="p-4 d-inline">Propagace</TitleH3>
            <SpanWithPadding>Facebook Ads</SpanWithPadding>
            <SpanWithPadding>TikTok Ads</SpanWithPadding>
            <SpanWithPadding>
              Heureka a&nbsp;Zboží, Beed, Mergado
            </SpanWithPadding>
            <SpanWithPadding>Google Ads a&nbsp;Seznam Sklik</SpanWithPadding>
            <SpanWithPadding>
              E‑mailing na Mailkitu včetně automatiky
            </SpanWithPadding>
            <SpanWithPadding>Správa produktových feedů</SpanWithPadding>
            <SpanWithPadding>
              Glami, Domodi, Ceneo, Shopalike, Kelkoo
            </SpanWithPadding>
          </Col>
          <Col md={6} className="d-flex flex-column align-items-center">
            <TitleH3 className="p-4 d-inline">Analytika a web</TitleH3>
            <SpanWithPadding>Pomoc s&nbsp;UX a&nbsp;SEO</SpanWithPadding>
            <SpanWithPadding>
              Google Analytics (včetně GA4) a&nbsp;GTM
            </SpanWithPadding>
            <SpanWithPadding>Cookie consent</SpanWithPadding>
            <SpanWithPadding>Klíčové analýzy</SpanWithPadding>
            <SpanWithPadding>
              Analýzy webu a&nbsp;konzultace jeho úprav
            </SpanWithPadding>
            <SpanWithPadding>Cost importy</SpanWithPadding>
          </Col>
        </Row>
      </Container>

      <Medailon
        personName="Klaudie Semelová"
        position="marketingová specialistka,<br/>vedoucí projektu"
        img={Klaudie}
      >
        <p>
          Jsem šťastná, že se spolupráce celé ty roky nese na vzájemné důvěře
          a&nbsp;přátelské úrovni. Setkáváme se na eventech, slavíme na
          vánočních večírcích a&nbsp;ani strategické a plánovací schůzky se
          neobejdou bez veselých historek. &#128578;
        </p>
      </Medailon>

      <Container className="text-center w-md-50 w-xl-25 pb-5">
        <p>
          A&nbsp;k&nbsp;tomu kolegové z{" "}
          <a href="//igloonet.hosting/" target="_blank" rel="noreferrer">
            hostingu
          </a>{" "}
          postavili pro Snowboard Zezula privátní cloud, řeší správu Linuxových
          serverů a&nbsp;chystají high‑available řešení celého e‑shopu.
        </p>
        <p>Rozjeďte to s&nbsp;námi ve velké parádě taky!</p>
      </Container>

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Snowboard
